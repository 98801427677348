import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import PageBuilder from "../components/pageBuilder"

export const query = graphql`
  query ($slug: String) {
    sanityPage(slug: {current: {eq: $slug}}) {
      title
      slug {current}
      _rawHero
      _rawBody(resolveReferences: {maxDepth: 10})
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const PageTemplate = (props) => {
  const page = props.data.sanityPage
  const customTitle = page.seo && page.seo.metaTitle ? page.seo.metaTitle : null
  const description = page.seo && page.seo.description ? page.seo.description : null
  const image = page.seo && page.seo.openGraphImage && page.seo.openGraphImage.asset !== null ? page.seo.openGraphImage.asset.url : null

  return (
    <Layout theme="light">
      <Helmet>
        {page.seo && page.seo.noIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <SEO
        title={page.title}
        customTitle={customTitle}
        description={description}
        image={image}
      />
      <Hero {...page._rawHero} theme="light" graph="radarPink" />
      <PageBuilder blocks={page._rawBody} />
    </Layout>
  )
}

export default PageTemplate
